import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const DeliveryCard = (props) => {
  return (
    <div className="col">
      <div className="hotel_img">
        <LazyLoadImage effect="blur" data-src={props.image} data-srcSet={props.srcSet} alt={props.imageAlt} className="lazyload" />
      </div>
      <div className="hotel-content-container">
        <div className="hotel-content">
          <h4>{props.title}</h4>
          <p>{props.text}</p><br/>
          {props.subtext && <p className='delivery-subtext'>{props.subtext}</p>}
          <div className='book_hotel_btn'>
            <a href={props.url} target="_blank" rel="noopener noreferrer"><button className={props.color? props.color : "btn btn-bordered"}>{props.btnText}</button></a>&emsp;&emsp;&emsp;
            {props.anotherUrl ? <a href={props.anotherUrl} target="_blank" rel="noopener noreferrer"><button className="btn btn-bordered">{props.anotherBtnText}</button></a>: ''}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryCard;