import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';


import Layout from '../components/layout';
import SEO from '../components/seo';

//components
import ImageBackground from '../components/imagebackground';
import TabCard from '../components/tabcard';
import Quote from '../components/quote';
import settings from '../../settings';
import BmwDeliveryExpGlance from '../components/bmwGlance/bmwDeliveryExpGlance';
import DeliveryInstructionsHeader from '../components/deliveryInstructionsHeader';


//images
const img1 = `${settings.IMAGES_BASE_URL}/images/delivery/delivery-banner-400.webp`;
const img2 = `${settings.IMAGES_BASE_URL}/images/delivery/delivery-banner-800.webp`;
const img3 = `${settings.IMAGES_BASE_URL}/images/delivery/delivery-banner-1200.webp`;
const xposter = `${settings.IMAGES_BASE_URL}/images/delivery/slide-1.jpg`;
const xposter1 = `${settings.IMAGES_BASE_URL}/images/delivery/slide-1-400.webp`;
const xposter2 = `${settings.IMAGES_BASE_URL}/images/delivery/slide-1-800.webp`;
const xposter3 = `${settings.IMAGES_BASE_URL}/images/delivery/slide-1-1200.webp`;
const slide2_400 = `${settings.IMAGES_BASE_URL}/images/delivery/slide-2-400.webp`;
const slide2_800 = `${settings.IMAGES_BASE_URL}/images/delivery/slide-2-800.webp`;
const slide2_1200 = `${settings.IMAGES_BASE_URL}/images/delivery/slide-2-1200.webp`;
const slide2 = `${settings.IMAGES_BASE_URL}/images/delivery/slide-2.jpg`;

const arrival = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-performance-center-delivery.jpg`;
const arrival1 = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-performance-center-delivery-400.webp`;
const arrival2 = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-performance-center-delivery-800.webp`;
const arrival3 = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-performance-center-delivery-1200.webp`;
const quotebackground = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-track-quote.jpg`;
const quotebackground1 = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-track-quote-400.webp`;
const quotebackground2 = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-track-quote-800.webp`;
const quotebackground3 = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-track-quote-1200.webp`;
const prevArrow = `${settings.IMAGES_BASE_URL}/images/assets/delivery-prev-arrow.svg`;
const nextArrow = `${settings.IMAGES_BASE_URL}/images/assets/delivery-next-arrow.svg`
const deliveryBanner = `${settings.IMAGES_BASE_URL}/images/delivery/delivery-banner.jpg`;
const mobDeliveryBanner = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-delivery-mobile.jpg`;

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} title="next-arrow">
      <LazyLoadImage effect="blur" src={nextArrow} alt="Previous Arrow" />
    </button>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
      <button className={className} onClick={onClick} title="prev-arrow">
      <LazyLoadImage effect="blur" src={prevArrow} alt="Previous Arrow" />
    </button>
  );
};

const DeliveryPage = () => {
  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div>
        <div className="connecting-line" />
        <ul className="slider-dots"> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className="dot">{i + 1}</div>
  };

  return (
    <Layout>
      <div className="page__delivery">
        <SEO title="BMW New Vehicle Delivery | BMW Performance Driving School" description="Order your next BMW, and take delivery of it right here at the BMW Performance Center in South Carolina. We'll celebrate your milestone with a day of thrills and smiles." />
          <ImageBackground
            imageDesktop={deliveryBanner}
            imageMobile={mobDeliveryBanner}
            imageAlt="Delivery banner"
            hideMobileHeader={true}
            srcSet={img1 + " 400w, "+ img2+" 800w, " + img3+" 1200w"  }
          />

        <section className="delivery" height="400">
          <div className="container">
            <div className="delivery__header" height="400">
              <h1>BMW PERFORMANCE CENTER DELIVERY EXPERIENCE</h1>
              <p>
                You can choose to take delivery of your new BMW right here at the BMW Performance Center in South Carolina. Make it a moment you’ll never forget.
              </p>
            </div>
          </div>
          <Quote
            image={quotebackground}
            image1={quotebackground1}
            image2={quotebackground2}
            image3={quotebackground3}
            leftAlignedText="“We had an incredible time. Thank you for your"
            rightAlignedText="hospitality and an amazing, safe & fun experience!”"
            client="- Neil P."
          />
        </section>

        <section className="deliverysteps">
          <div className="container">
            <h2>A MOMENT LIKE THIS</h2>
            <div className="wizard">
              <Slider {...sliderSettings}>
                <div label="1">
                  <TabCard
                    rowType="row"
                    image={xposter}
                    srcSet={xposter1 + " 400w, "+ xposter2+" 800w, " + xposter3+" 1200w"}
                    imageAlt="CHOOSE YOUR BMW"
                    header="1. CHOOSE YOUR BMW"
                    subheader="IT’S THE HARDEST PART"
                    text="It all starts with a visit to your local BMW Center. Choose any BMW and customize to your personal taste. Your Client Advisor will guide you through the ordering and delivery process and then notify you when your BMW Performance Center Delivery Experience can be scheduled."
                    btnText="Build your BMW"
                    btnUrl="https://www.bmwusa.com/build-your-own.html#/series"
                  />
                </div>
                <div label="2">
                  <TabCard
                    rowType="row"
                    image={slide2}
                    srcSet={slide2_400 + " 400w, "+ slide2_800+" 800w, " + slide2_1200+" 1200w"  }
                    imageAlt="BMW Delivery experience"
                    header="2. YOUR BMW PERFORMANCE CENTER DELIVERY EXPERIENCE"
                    subheader="THE FUN BEGINS"
                    text="Upon arrival at the BMW Performance Center and a brief classroom session, you’ll hop into a BMW that’s been selected for you based on the model you’ve purchased. Out on the track, you’ll learn the ins and outs of your model, how it handles at the limit and just how much it makes you smile."
                  />
                </div>
                <div label="3">
                  <TabCard
                    rowType="row"
                    image={arrival}
                    srcSet={arrival1 + " 400w, "+ arrival2+" 800w, " + arrival3+" 1200w"  }
                    imageAlt="BMW new vehicle delivery"
                    header="3. BMW DELIVERY"
                    subheader="YOUR NEW BABY IS HERE"
                    text="Your moment is here. You’ll be given a personal tour of your new BMW with one of our delivery specialists. They’ll go over all the advanced features and answer any questions you may have. Then, it’s time for you to get behind the wheel and take your maiden voyage as you head for home."
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <BmwDeliveryExpGlance/>
        <DeliveryInstructionsHeader page="delivery"/>
        <section className="cta">
          <div className="container">
            <h2>EXPLORE EVEN MORE.</h2>
            <p>
              Make the most of your trip with a visit to our Zentrum Museum, a factory tour and a trip to beautiful
              Greenville in South Carolina.
            </p>
            <div className="buttons">
            <a
                href="https://www.bmwusa.com/build-your-own.iframe.html#/series"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-black">Build your baby now</button>
              </a>

              <Link to="/deliveryfaq">
                <button className="btn btn-black">Delivery FAQ</button>
              </Link>
              <a
                href="https://www.bmwgroup-plants.com/spartanburg/en/plant-tours-zentrum-museum.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-black">Visit the Zentrum's website</button>
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DeliveryPage;