import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import DeliveryCard from '../components/deliveryCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import settings from '../../settings';

const hartness = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/hotel-hartness.jpg`;
const hartness1 = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/hotel-hartness-400.webp`;
const hartness2 = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/hotel-hartness-800.webp`;
const hartness3 = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/hotel-hartness-1200.webp`;
const bohemian = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/bohemian.jpg`;
const bohemian1 = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/bohemian-400.webp`;
const bohemian2 = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/bohemian-800.webp`;
const bohemian3 = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/bohemian-1200.webp`;


class DeliveryInstructionsHeader extends React.Component {
  state = {
    width: ''
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isDesktop = width >= 1200;
    return (
      <>
        <div className="container">
          <div className="delivery_instructions__header">
            <h2>{this.props.header}</h2>
            <p>
              {this.props.subheader}
            </p>
            <br/>
            <br/>
            <h2>RECOMMENDED HOTELS</h2>
            <p>
              If you’d like to relax before your big day, we’re partnered with luxurious hotels in the area that offer a selection of packages for our Delivery customers to choose from.</p>
          </div>
        </div>
        {isDesktop ? (
          <div className="delivery_instructions__cards">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className='hotel_image'>
                    <LazyLoadImage effect="blur" data-src={hartness} data-srcSet={hartness1 + " 400w, "+ hartness2+" 800w, " + hartness3+" 1200w"  } alt="HOTEL HARTNESS" className="lazyload" />
                  </div>
                </div>
                <div className="col">
                  <div className='hotel_image'>
                    <LazyLoadImage effect="blur" data-src={bohemian} data-srcSet={bohemian1 + " 400w, "+ bohemian2+" 800w, " + bohemian3+" 1200w"  } alt="Grand Bohemian Lodge" className="lazyload" />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col">
                  <div className='hotel_content'>
                    <h4>HOTEL HARTNESS</h4>
                    <p>A gracious and inviting retreat featuring 69 suites in the main hotel and four distinctive spa suites, each finely appointed in a fresh, contemporary motif.</p><br/>
                    {this.props.page !== 'delivery' && <p className='delivery-subtext'>There’s no booking code needed. The link below is tied to special rates and packages for our customers.</p>}
                  
                  </div>
                </div>
                <div className="col">
                  <div className='hotel_content'>
                    <h4>Grand Bohemian Lodge</h4>
                    <p>Perched on the falls of the iconic Reedy River, this lavish lodge is inspired by the spirit of Greenville with carefully curated detailing that pays tribute to surrounding Falls Park.</p><br/>
                    {this.props.page !== 'delivery' && <p className='delivery-subtext'><>Under corporate rates, enter <span class="bold_text">PJ5</span> to view packages they’ve developed for our guests. You must use the code to receive shuttle service and breakfast.</></p>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col">
                  <div className='hotel_content'>
                    <a href= {this.props.page==="delivery" ? "https://www.hotelhartness.com/":"https://www.hotelhartness.com/BMW-owners"} target="_blank" rel="noopener noreferrer"><button className={this.props.color? this.props.color : "btn btn-bordered"}>{this.props.page == 'delivery' ?  'Learn more' : 'Book Hotel Hartness'}</button></a>&emsp;&emsp;&emsp;
                  </div>
                </div>
                <div className="col">
                  <div className='hotel_content'>
                    <a href= {this.props.page==="delivery" ? "https://www.marriott.com/en-us/hotels/gspak-grand-bohemian-lodge-greenville-autograph-collection/overview/":"https://www.marriott.com/event-reservations/reservation-link.mi?id=1707839647969&key=CORP&app=resvlink"} target="_blank" rel="noopener noreferrer"><button className={this.props.color? this.props.color : "btn btn-bordered"}>{this.props.page == 'delivery' ?  'Learn more' : 'Book Grand Bohemian'}</button></a>&emsp;&emsp;&emsp;
                  </div>
                </div>
              </div>
            </div>
          </div>) : (
            <div className="delivery_instructions__cards">
            <div className="container">
                <div className="row">
                  <DeliveryCard
                    image={hartness}
                    scrSet={hartness1 + " 400w, "+ hartness2+" 800w, " + hartness3+" 1200w"  }
                    imageAlt="HOTEL HARTNESS"
                    title="HOTEL HARTNESS"
                    text="A gracious and inviting retreat featuring 69 suites in the main hotel and four distinctive spa suites, each finely appointed in a fresh, contemporary motif."
                    subtext= {this.props.page==="delivery" ? "": "There’s no booking code needed. The link below is tied to special rates and packages for our customers."}
                    btnText= {this.props.page==="delivery" ? "Learn more" : "Book Hotel Hartness"}
                    url= {this.props.page==="delivery" ? "https://www.hotelhartness.com/":"https://www.hotelhartness.com/BMW-owners"}
                  />
                  <DeliveryCard
                    image={bohemian}
                    scrSet={bohemian1 + " 400w, "+ bohemian2+" 800w, " + bohemian3+" 1200w"  }
                    imageAlt="Grand Bohemian Lodge"
                    title="Grand Bohemian Lodge"
                    text="Perched on the falls of the iconic Reedy River, this lavish lodge is inspired by the spirit of Greenville with carefully curated detailing that pays tribute to surrounding Falls Park."
                    subtext={this.props.page==="delivery" ? "": <>Under corporate rates, enter <span class="bold_text">PJ5</span> to view packages they’ve developed for our guests. You must use the code to receive shuttle service and breakfast.</>}
                    btnText={this.props.page==="delivery" ? "Learn more" : "Book Grand Bohemian"}
                    url= {this.props.page==="delivery" ? "https://www.marriott.com/en-us/hotels/gspak-grand-bohemian-lodge-greenville-autograph-collection/overview/":"https://www.marriott.com/event-reservations/reservation-link.mi?id=1707839647969&key=CORP&app=resvlink"}
                  />
                </div>
              </div>
            </div>
          ) 
        }
      </>
    );
  }
}
  
export default DeliveryInstructionsHeader;