import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwDeliveryExpGlance = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>THE BMW DELIVERY EXPERIENCE AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>Can I take delivery of any BMW at the Performance Center? </h5>
                <p>All new models can be delivered at the BMW Performance Center based on availability, production, shipping and scheduling. Delivery reservations are limited and not guaranteed. Factors like production, shipping and scheduling help determine if the delivery reservation can be confirmed. It is not available for new BMWs already on dealer lots, or CPO BMWs.</p><br/>
              </div>
              <div>
                <h5>Is the BMW Delivery Experience available in California?</h5>
                <p>You can only take delivery of your new BMW vehicle in South Carolina.</p>
              </div>
              <div>
                <h5>Where is the BMW Performance Center located?</h5>
                <p>
                  <a href="https://www.google.com/maps/place/1155+SC-101,+Greer,+SC+29651/@34.9045674,-82.1818842,17z/data=!3m1!4b1!4m6!3m5!1s0x885781f9de178019:0xe5e780c9038ea2ec!8m2!3d34.904563!4d-82.1793039!16s%2Fg%2F11kj040tfv?entry=ttu" target="_blank" rel="noopener noreferrer">
                    1155 SC-101 South, Greer, SC 29651
                  </a>
                  <br/> 
                  (888) 345-4269</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Is a hotel stay included with my BMW Performance Center Delivery Experience?</h5>
                <p>
                  We do not include a hotel stay as part of your experience, as travel plans vary from client to client. However, we have partnered with luxurious hotels in the Greenville / Spartanburg area that offer special packages in addition to accommodations for our clients at a discounted rate.
                </p>
              </div>
              <div>
                <h5>Does BMW book everything for me?</h5>
                <p>
                  Your BMW Center will work with you to book your delivery day. Accommodations and travel are booked directly by you – as you have many options to choose from within the area – including our recommended partner properties, which offer special discounts for our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwDeliveryExpGlance;