import React from 'react';

const Quote = (props) => {
  return (
    <section className="quote" style={{
      backgroundImage: `url(${props.image})`,
      backgroundImage: `image-set(url(${props.image1}) 1x,url(${props.image2}) 2x, url(${props.image3}) 3x)`
    }}>
    <div className="quote__content">
      <h3>{props.leftAlignedText}</h3>
      <h3 className="right-aligned">{props.rightAlignedText}</h3>
      <span>{props.client}</span>
    </div>
  </section>
  );
};

export default Quote;