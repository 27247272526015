import React from 'react';
import 'lazysizes';

const Tabcard = (props) => {
  return (
    <div className="tabcard">
      <div className={props.rowType}>
        <div className="col">
          <div className="tabcard__image">
            <img data-src={props.image} alt={props.imageAlt} data-srcset={props.srcSet} className="lazyload" tabIndex="-1" />
          </div>
        </div>
        <div className="col">
          <div className="tabcard__content">
            <h3>{props.header}</h3>
            <h4>{props.subheader}</h4>
            <p>{props.text}</p>
            {props.btnText ? <a href={props.btnUrl} tabIndex="-1" target="_blank" rel="noopener noreferrer" ><button className="btn btn-bordered" tabIndex="-1">{props.btnText}</button> </a>: null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tabcard;
